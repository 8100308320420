import React from 'react';
import API from '../helper/API';
import GlobalSettings from '../context/Context';

class Files extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      loading: false,
      loading_statement: '',
    };
    this.get_files = this.get_files.bind(this);
    this.delete_file = this.delete_file.bind(this);
    this.view_file = this.view_file.bind(this);
  }

  async delete_file(id) {
    const data = {file: id};
    this.setState({loading: true, loading_statement: 'Deleting file ...'});

    await API.post('delete_file/', data)
      .then((respone) => {
        const res = respone.data;
        if (res.data !== 0) {
          this.get_files();
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  async get_files() {
    const data = {user: window.sessionStorage.getItem('u_id')};
    this.setState({loading: true, loading_statement: 'Getting your files ...'});

    await API.post('get_files/', data)
      .then((respone) => {
        const res = respone.data;
        this.setState({files: res.data, loading: false, loading_statement: ''});
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  async view_file(id) {
    const data = {file: id};
    this.setState({loading: true, loading_statement: 'Prepering your file ...'});

    await API.post('view_file/', data)
      .then((respone) => {
        const res = respone.data;
        window.localStorage.setItem('view_file_temp', JSON.stringify(res.data));
        window.localStorage.setItem('view_file_temp_labels', JSON.stringify(res.labels));
        this.setState({loading: false, loading_statement: ''});
        window.location.href = '/home';
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  componentDidMount() {
    this.get_files();
  }

  render() {
    return (
      <>
        {this.state.loading === true ? (
          <p
            style={{
              fontWeight: '700',
              color: '#384047',
              textAlign: 'center',
              lineHeight: '1.5em',
              marginBottom: '0.2em',
              marginTop: '0.2em',
            }}
          >
            {this.state.loading_statement}
          </p>
        ) : (
          <div className="files_table">
            {this.state.files.length === 0 ? (
              <p
                style={{
                  fontWeight: '700',
                  color: '#384047',
                  textAlign: 'center',
                  lineHeight: '1.5em',
                  marginBottom: '0.2em',
                  marginTop: '0.2em',
                }}
              >
                You have not uploaded any files.
              </p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">File Name</th>
                    <th scope="col">Upload Date</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.files.map((file, i) => (
                    <tr key={i}>
                      <td>
                        <p>{file.f_name.replace(file.f_id + '_', '')}</p>
                      </td>
                      <td>
                        <p>{file.f_created_at}</p>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            this.view_file(file.f_id);
                          }}
                          className="view"
                        >
                          View
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            this.delete_file(file.f_id);
                          }}
                          className="delete"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </>
    );
  }
}

Files.contextType = GlobalSettings;
export default Files;
