import React, {Component} from 'react';
import API from '../helper/API';
import ReactLoading from 'react-loading';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: '',
      loading: false,
    };
    this.login = this.login.bind(this);
    this.submit_email = this.submit_email.bind(this);
    this.submit_password = this.submit_password.bind(this);
  }

  submit_email(event) {
    this.setState({email: event.target.value});
  }

  submit_password(event) {
    this.setState({password: event.target.value});
  }

  async login() {
    if ( this.state.email === '' || this.state.password === '') {
      this.setState({error: 'Email and password can not be empty'});
      return;
    }
    this.setState({loading: true});
    const data = {email: this.state.email, password: this.state.password};

    await API.post('login/', data).then((respone) => {
      const res = respone.data;
      if (res.data.u_id) {
        window.sessionStorage.setItem('u_id', res.data.u_id);
        window.sessionStorage.setItem('u_name', res.data.u_name);
        window.sessionStorage.setItem('u_admin', res.data.u_admin);
        window.sessionStorage.setItem('u_authenticated', true);
        this.setState({loading: false});
        window.location.href = '/home';
      } else {
        this.setState({loading: false});
        this.setState({error: 'Incorrect email or password'});
      }
    }).catch(function(error) {
      console.log(error);
    });
  }

  componentDidMount() {
    if (window.sessionStorage.getItem('u_authenticated')) {
      window.location.href = '/home';
    } else {
    }
  }

  render() {
    return (
      <div className="login_container">
        {
          this.state.loading === true ? (
            <ReactLoading
              type={'spin'}
              color={'green'}
              height={100}
              width={100}
            />
          ) : (
            <form action="index.html" method="post">
              <h1>
                <span style={{color: '#ee6117', fontWeight: 'bold', fontSize: '2.5rem'}}>
                  MOBi
                </span>
                <span style={{fontSize: '2.5rem'}}>SENSE</span>
              </h1>
              <p className='error_message'>{this.state.error}</p>
              <div className="form-content">
                <input
                  id="user-name"
                  name="user-name"
                  placeholder="Email"
                  type="text"
                  onChange={this.submit_email}
                />
                <input
                  id="password"
                  name="password"
                  placeholder="Password"
                  type="password"
                  onChange={this.submit_password}
                />
                <br />
                <div className="button" onClick={this.login}>
                Log in
                </div>
                <br />
              </div>
            </form>
          )
        }
      </div>
    );
  }
}

export default Login;
