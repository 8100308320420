import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {GrLogout} from 'react-icons/gr';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: [false, false],
      activeClassHome: '',
      activeClassFiles: '',
      pop: true,
    };
    this.logout = this.logout.bind(this);
  }

  logout() {
    sessionStorage.removeItem('u_id');
    sessionStorage.removeItem('u_name');
    sessionStorage.removeItem('u_admin');
    sessionStorage.removeItem('u_authenticated');
    window.location.href = '/';
  }

  handleLinksActive = (num) => {
    num === 0 ? this.setState({active: [true, false, false, false]}) : this.setState({active: [false, true, false, false]});
  };

  componentDidMount() {
    window.location.href === '/home' ? this.setState({activeClassHome: 'active'}) : this.setState({activeClassHome: ''});
    window.location.href === '/files' ? this.setState({activeClassFiles: 'active'}) : this.setState({activeClassFiles: ''});
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.active !== this.state.active) {
      this.state.active[0] === true ? this.setState({activeClassHome: 'active'}) : this.setState({activeClassHome: ''});
      this.state.active[1] === true ? this.setState({activeClassFiles: 'active'}) : this.setState({activeClassFiles: ''});
    }
  }

  render() {
    return (
      <ul className="Navbar">
        {sessionStorage.getItem('u_authenticated') ? (
          <>
            <li>
              <Link className="link" to="/home">
                Home
              </Link>
            </li>
            <li>
              <Link className="link" to="/files">
                Files
              </Link>
            </li>
            <li>
              {window.sessionStorage.getItem('u_admin') == 'true' ? (
                <Link className="link" to="/admin">
                  Admin Dashboard
                </Link>
              ) : (
                <></>
              )}
            </li>
            <li style={{float: 'right'}}>
              <Link
                className="link"
                to={() => false}
                onClick={this.logout}
              >
                <GrLogout className="logOutIcon"/>
              </Link>
            </li>
            <li style={{float: 'right'}}>
              <Link className="link" to={() => false}>
                {window.sessionStorage.getItem('u_name')}
              </Link>
            </li>
          </>
        ) : (
          <li style={{float: 'right'}}>
            <Link className="link active" to={() => false}>
              About
            </Link>
          </li>
        )}
      </ul>
    );
  }
}

export default Navbar;
