import axios from 'axios';

// const url = 'http://127.0.0.1:8000/';
const url = 'https://kasut-data-analysis-api.herokuapp.com/';

const Axios = axios.create({
  baseURL: url,
});

Axios.interceptors.request.use(function(config) {
  config.metadata = {startTime: new Date()};
  return config;
}, function(error) {
  return Promise.reject(error);
});

Axios.interceptors.response.use(function(response) {
  response.config.metadata.endTime = new Date();
  response.duration = response.config.metadata.endTime - response.config.metadata.startTime;
  return response;
}, function(error) {
  error.config.metadata.endTime = new Date();
  error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
  return Promise.reject(error);
});

export default Axios;
