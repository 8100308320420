import React, {Component} from 'react';
import Label from './label.js';

class EventsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <h1 style={{marginBottom: '3rem'}}>Proof-of-Concept-Dashboard</h1>
        <h3>
          Pseudo-IMSI<span> (C594ACD4EA814C998BB060433DAA0B5B)</span>
        </h3>
        <div className="labels">
          <Label val={this.props.labels ? this.props.labels.shops : 'None'} event="shops visited" />{' '}
          <Label val={this.props.labels ? this.props.labels.time_in_transition + '%' : 'None'} event="time in transition" />
          <Label val={this.props.labels ? this.props.labels.time_in : 'None'} event="time in" />{' '}
          <Label val={this.props.labels ? this.props.labels.time_out : 'None'} event="time out" />{' '}
          <Label val={this.props.labels ? this.props.labels.duration_of_stay : 'None'} event="duration of stay" />
          <Label val={this.props.rountrip ? this.props.rountrip : 'None'} event="rountrip (seconds)" />
        </div>
      </>
    );
  }
}

export default EventsContainer;
