import React from 'react';

class Legend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="legend">
        <div
          className="dot-legend"
          style={{backgroundColor: this.props.color}}
        ></div>
        <p style={{color: '#a7a4a8', fontWeight: '500',
          marginTop: '15px'}}>{this.props.name}</p>
      </div>
    );
  }
}

export default Legend;
