import React from 'react';
import API from '../helper/API';
import Modal from 'react-bootstrap/Modal';
import {Button, Form} from 'react-bootstrap';

class AdminPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      show_modal: false,
      u_name: '',
      u_email: '',
      u_password: '',
      u_admin: false,
      loading: false,
      loading_statement: '',
    };
    this.get_users = this.get_users.bind(this);
    this.delete_user = this.delete_user.bind(this);
    this.create_user = this.create_user.bind(this);
    this.switchModal = this.switchModal.bind(this);
    this.handleUserForm = this.handleUserForm.bind(this);
  }

  switchModal() {
    this.setState({show_modal: !this.state.show_modal});
  }

  handleUserForm(event, type) {
    if (type === 'name') {
      this.setState({u_name: event.target.value});
    }
    if (type === 'email') {
      this.setState({u_email: event.target.value});
    }
    if (type === 'password') {
      this.setState({u_password: event.target.value});
    }
    if (type === 'admin') {
      this.setState({u_admin: event.target.value});
    }
  }

  async get_users() {
    const data = {user: window.sessionStorage.getItem('u_id')};
    this.setState({loading: true, loading_statement: 'Getting users ...'});

    await API.post('get_users/', data)
      .then((respone) => {
        const res = respone.data;
        this.setState({
          users: res.data,
          loading: false,
          loading_statement: '',
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  async create_user() {
    const data = {
      name: this.state.u_name,
      email: this.state.u_email,
      password: this.state.u_password,
      admin: this.state.u_admin,
    };

    await API.post('create_user/', data)
      .then((respone) => {
        const res = respone.data;
        if (res.data !== '') {
          this.get_users();
          this.setState({
            u_name: '',
            u_email: '',
            u_password: '',
            u_admin: false,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  async delete_user(id) {
    const data = {user: id};

    await API.post('delete_user/', data)
      .then((respone) => {
        const res = respone.data;
        if (res.data !== 0) {
          this.get_users();
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  componentDidMount() {
    this.get_users();
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <p
            style={{
              fontWeight: '700',
              color: '#384047',
              textAlign: 'center',
              lineHeight: '1.5em',
              marginBottom: '0.2em',
              marginTop: '0.2em',
            }}
          >
            {this.state.loading_statement}
          </p>
        ) : (
          <div className="files_table">
            <Button
              style={{float: 'right'}}
              variant="success"
              onClick={this.switchModal}
            >
              Add User
            </Button>
            <table className="table">
              <thead>
                <tr>
                  <th>id</th>
                  <th>UserName</th>
                  <th>Email</th>
                  <th>Files Count</th>
                  <th>Is Admin</th>
                  <th>Create Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.users.map((user, i) => (
                  <tr key={i}>
                    <td>
                      <p>{user.u_id}</p>
                    </td>
                    <td>
                      <p>{user.u_name}</p>
                    </td>
                    <td>
                      <p>{user.u_email}</p>
                    </td>
                    <td>
                      <p>{user.u_files_count}</p>
                    </td>
                    <td>
                      <p>{user.u_admin === true ? 'True' : 'False'}</p>
                    </td>
                    <td>
                      <p>{user.u_created_at}</p>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          this.delete_user(user.u_id);
                        }}
                        className="delete"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Bootstrap Modals */}
            <Modal
              show={this.state.show_modal}
              onHide={this.switchModal}
              style={{
                borderRadius: '15px',
              }}
            >
              <Modal.Header>
                <Modal.Title
                  style={{
                    marginLeft: '5rem',
                  }}
                >
                  Add New User
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Full-Name"
                      onChange={(e) => this.handleUserForm(e, 'name')}
                      className="ModalInput"
                    />
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      onChange={(e) => this.handleUserForm(e, 'email')}
                      className="ModalInput"
                    />
                    <Form.Control
                      type="password"
                      placeholder="password"
                      onChange={(e) => this.handleUserForm(e, 'password')}
                      className="ModalInput"
                    />
                    <input
                      onChange={(e) => this.handleUserForm(e, 'admin')}
                      type="checkbox"
                      value={1}
                    />{' '}
                    set user as admin
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  style={{float: 'right', borderRadius: '4px'}}
                  variant="secondary"
                  onClick={this.switchModal}
                >
                  Cancel
                </Button>
                <Button
                  style={{float: 'right', borderRadius: '4px'}}
                  variant="success"
                  onClick={() => {
                    this.create_user();
                    this.switchModal();
                  }}
                >
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </>
    );
  }
}

export default AdminPanel;
