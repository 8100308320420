import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './../components/Navbar.js';
import Home from '../pages/Home';
import Files from '../pages/Files';
import Login from '../pages/Login';
import AdminPanel from '../pages/AdminPanel';
import PrivateRoute from './PrivateRoute .js';

class Routing extends React.Component {
  render() {
    return (
      <>
        <Router>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route
              exact
              path="/files"
              element={
                <PrivateRoute>
                  <Files />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <AdminPanel />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/home"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </>
    );
  }
}

export default Routing;
