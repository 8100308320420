import React, {createContext} from 'react';

const GlobalSettings = createContext();

export class ContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      u_id: '',
      u_name: '',
      u_admin: '',
      u_authenticated: '',
      u_chart_data: null,
    };
    this.userName = this.userName.bind(this);
    this.userID = this.userID.bind(this);
    this.userAdmin = this.userAdmin.bind(this);
    this.userAuthenticated = this.userAuthenticated.bind(this);
    this.userFiles = this.userFiles.bind(this);
    this.UserChartData = this.UserChartData.bind(this);
  }

  UserChartData(data) {
    this.setState({u_chart_data: data});
  }

  userID(id) {
    this.setState({u_id: id});
  }

  userName(name) {
    this.setState({u_name: name});
  }

  userAdmin(admin) {
    this.setState({u_admin: admin});
  }

  userAuthenticated(auth) {
    this.setState({u_authenticated: auth});
  }

  userFiles(file) {
    this.setState({u_files: file});
  }

  render() {
    return (
      <GlobalSettings.Provider value={{
        ...this.state,
        userName: this.userName,
        userID: this.userID,
        userAdmin: this.userAdmin,
        userAuthenticated: this.userAuthenticated,
        userFiles: this.userFiles,
        UserChartData: this.UserChartData}}>
        {this.props.children}
      </GlobalSettings.Provider>
    );
  }
}

export default GlobalSettings;
