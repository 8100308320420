import React, {Component} from 'react';
// import logo from '../logo.png';
import MOBiSENSE from '../MOBiSENSE.png';

class MobisenseContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '2rem',
        }}
      >
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img src={MOBiSENSE} alt="Logo" style={{height: '8rem'}}/>
        </div>
        <div
          style={{
            border: '1px solid black',
            height: '8rem',
            width: '18rem',
            marginTop: '1rem',
            marginLeft: '7rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <div style={{marginTop: '1.5rem', marginRight: '0.3rem'}}>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <h5
                style={{
                  marginTop: '-1rem',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                Test site 4
              </h5>
            </div>
            <p style={{marginTop: '0.5rem'}}>
              26° 18&apos; 21.1896° 10&apos; 14.484&quot;</p>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <h5
                style={{
                  marginTop: '-0.5rem',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                5G NSA Network
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MobisenseContainer;
