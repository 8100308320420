import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from 'react';
import Routing from './config/Routing';
import {ContextProvider} from './context/Context';

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <Routing />
      </ContextProvider>
    </div>
  );
}

export default App;
