import React, {createRef} from 'react';
import EventsContainer from '../components/eventsContainer';
import MobisenseContainer from '../components/mobisenseContainer';
import API from '../helper/API';
import Legend from '../components/chart/legend';
import HSBar from 'react-horizontal-stacked-bar-chart';
// import randomColor from 'randomcolor';
import GlobalSettings from '../context/Context';
import ReactLoading from 'react-loading';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.myref = createRef();
    this.state = {
      file: {},
      file_name: 'Choose a file',
      unique_labels: {},
      rountrip: 0,
      data: [],
      labels: {},
      timeStamps: [],
      dummy_data: [{label: 'default', value: 100, color: 'grey'}],
      button_disable: true,
      loading: false,
      error: '',
    };
    this.Upload = this.Upload.bind(this);
    this.Proccess = this.proccess.bind(this);
    this.Reset = this.Reset.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
      button_disable: false,
      file_name: event.target.files[0].name,
    });
  };

  Reset() {
    this.setState({
      data: [],
      file: '',
      button_disable: true,
      file_name: 'Choose a file',
    });
    this.myref.current.value = null;
  }

  async Upload() {
    this.setState({loading: true});
    const formData = new FormData();
    formData.append('file', this.state.file);
    formData.append('user', window.sessionStorage.getItem('u_id'));

    await API.post('process_csv/', formData)
      .then((respone) => {
        const res = respone.data;
        if (res.error != '') {
          this.setState({error: res.error, loading: false});
        } else {
          this.setState({
            data: this.proccess(res.data, res.labels.unique_labels),
            labels: res.labels,
            loading: false,
            error: '',
            rountrip: respone.duration / 1000,
            timeStamps: res.labels.time_stamps,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  proccess(data, UniqueLabels) {
    const colors = ['#FF0000', '#800080', '#008000', '#000080', '#008080', '#006400', '#ff8c00', '#483d8b', '#b22222'];
    let count = 0;

    const LabelsColors = {};
    for (const label of UniqueLabels) {
      // LabelsColors[label] = randomColor();
      LabelsColors[label] = colors[count];
      count++;
    }
    this.setState({unique_labels: LabelsColors});

    const result = [];
    for (const value of data) {
      value['color'] = LabelsColors[value['label']];
      result.push(value);
    }
    return result;
  }

  componentDidMount() {
    if (window.localStorage.getItem('view_file_temp')) {
      this.setState({
        data: this.proccess(JSON.parse(window.localStorage.getItem('view_file_temp')),
          JSON.parse(window.localStorage.getItem('view_file_temp_labels'))['unique_labels']),
        labels: JSON.parse(window.localStorage.getItem('view_file_temp_labels')),
        timeStamps: JSON.parse(window.localStorage.getItem('view_file_temp_labels'))['time_stamps'],
        button_disable: false,
      });
      window.localStorage.removeItem('view_file_temp');
      window.localStorage.removeItem('view_file_temp_labels');
    }
  }

  render() {
    return (
      <div className="App">
        <div className="container">
          {this.state.data.length === 0 ? (
            <span>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{marginLeft: '2rem'}}>
                  <EventsContainer/>
                </div>
                <MobisenseContainer />
              </div>
              <div style={{marginLeft: '1rem'}}>
                <div className="chartcontainer">
                  <h3>Customer visit itinerary</h3>
                  <p className='error_message'>{this.state.error}</p>
                  <div className="legendscontainer">
                    {this.state.dummy_data.map((key, i) => (
                      <Legend color={key.color} key={i} name={key.label} />
                    ))}
                  </div>
                  <div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <HSBar
                        height="9.5rem"
                        fontColor="rgb(50,20,100)"
                        id="myChart"
                        data={this.state.dummy_data}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          marginTop: '0.5rem',
                        }}
                      >
                        <p>0</p>
                      </div>
                      <h5
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '-0.8rem',
                          marginLeft: '6rem',
                        }}
                      >
                        label
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          ) : (
            <span>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{marginLeft: '2rem'}}>
                  <EventsContainer rountrip={this.state.rountrip}
                    labels={this.state.labels}/>
                </div>
                <MobisenseContainer />
              </div>
              <div style={{marginLeft: '2rem'}}>
                <div className="chartcontainer">
                  <h3>Customer visit itinerary</h3>
                  <p className='error_message'>{this.state.error}</p>
                  <div className="legendscontainer">
                    {Object.keys(this.state.unique_labels).map((key, i) => (
                      <Legend color={this.state.unique_labels[key]} key={i} name={key} />
                    ))}
                  </div>
                  <div className="chart">
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <HSBar
                        height="12rem"
                        fontColor="rgb(50,20,100)"
                        id="myChart"
                        data={this.state.data}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          marginTop: '0.5rem',
                        }}
                      >
                        {this.state.timeStamps.map((key, i) => (
                          <p key={i} >{key}:00</p>
                        ))}
                      </div>
                      <h5
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '-0.8rem',
                          marginLeft: '6rem',
                        }}
                      >
                        time of the day
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          )}
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          {this.state.loading === true ? (
            <ReactLoading
              type={'spin'}
              color={'green'}
              height={100}
              width={100}
            />
          ) : (
            <>
              <label htmlFor="file-upload" className="custom-file-upload">
                <i className="fa fa-cloud-upload"></i> {this.state.file_name}
              </label>
              <input
                className="file_input"
                id="file-upload"
                type="file"
                onChange={this.onFileChange}
                ref={this.myref}
              />
              <button
                className="upload"
                onClick={this.Upload}
                disabled={this.state.button_disable}
              >
                upload
              </button>
              <button
                className={
                  this.state.button_disable ? 'inactive_reset' : 'reset'
                }
                onClick={this.Reset}
                disabled={this.state.button_disable}
              >
                Reset
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
}

Home.contextType = GlobalSettings;

export default Home;
