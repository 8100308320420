import React, {Component} from 'react';

class Label extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="label">
        <p>{this.props.val}</p>
        <p style={{color: '#a7a4a8'}}>{this.props.event}</p>
      </div>
    );
  }
}

export default Label;
